<script setup>
import Sticky from "sticky-js";

const {cart, toggleCart} = useCartStore();
const {menus, options} = useGlobalStore();
const {itemsInCart} = storeToRefs(useCartStore());
const showMenu = ref(false);
const left_menu = menus?.find((menu) => menu.name === "left menu");
const right_menu = menus?.find((menu) => menu.name === "right menu");
const mobile_menu = menus?.find((menu) => menu.name === "Mobile menu");

const toggleMenu = () => {
  showMenu.value = !showMenu.value;
};
onMounted(() => {
  setTimeout(() => {

    const sticky = new Sticky(".top-menu--mobile", {
      wrap: false,
      class: "sticky",
    });
  }, 500);
});
</script>

<template>
  <div>
    <HeaderTopBarMobile/>
    <div class="top-menu--mobile mobile_only sticky-menu sticky" data-sticky-class="sticky">
      <div class="container">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <button @click="toggleMenu" class="menutBtn burger burger-slip d-block">
              <div class="burger-lines"></div>
            </button>
            <div class="ic-cart mr-4 align-self-end">
              <button class="" @click="toggleCart()">
                <div class="ic-cart-num" :key="itemsInCart">
                  {{ itemsInCart }}
                </div>
                <SvgoCartIc/>
              </button>
            </div>
          </div>
          <div class="logo text-center">
            <NuxtLink to="/" class="">
              <nuxt-img
                  class="mx-auto"
                  v-if="options?.logo.node"
                  :src="options?.logo?.node?.sourceUrl"
                  :alt="options.logo?.node?.altText"
              />
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </div>
  <VNavigationDrawer direction="rtl" location="right" size="500px" class="menu-drawer mobile_only"
                     :model-value="showMenu">
    <div class="mobile_only">
      <div
          class="mobile-menu__top cover"
          style="background-image: url('https://admin.sasa.co.il/wp-content/uploads/2020/12/menu_bg.jpg')"
      >
        <button @click="toggleMenu" class="menutBtn burger open burger-slip d-block">
          <div class="burger-lines"></div>
        </button>
        <div class="flex justify-center items-center flex-column">
          <div class="text-center">
            <img
                class="ml-3"
                width="50"
                height="50"
                src="https://admin.sasa.co.il/wp-content/themes/sasa/assets/images/svg/defoult avatar.svg"
                alt="תמונה של מיניון לתמונת פרופיל"
            />
            <!--                <img src="--><!--" alt="--><!--"/>-->
          </div>
          <div class="text-center">
            <nuxt-link href="/my-account/" class="login-reg-link"> היי, admin</nuxt-link>
          </div>
        </div>
      </div>
      <div class="mobile-menu__inner">
        <div class="menu-mobile-menu-container">
          <ul id="menu-mobile-menu" class="menu">
            <li class="menu-item" v-for="menuItem in mobile_menu.menuItems.nodes">
              <NuxtLink :to="menuItem.uri">{{ menuItem.label }}</NuxtLink>
            </li>

          </ul>
        </div>
      </div>
    </div>
  </VNavigationDrawer>
</template>

<style lang="scss">
.menu-drawer {
  .el-drawer__body {
    padding: 0;
  }
}

.top-menu--mobile.sticky {
  background: #000;
}
</style>
